<template>
  <div class="main">
    <img class="t1"
         src="~@/assets/images/ambassador/t1.png"
         alt="">
    <img class="t2"
         src="~@/assets/images/ambassador/t2.png"
         alt="">
    <img class="t3"
         src="~@/assets/images/ambassador/t3.png"
         alt="">

    <div class="qecode-box">
      <img src="~@/assets/images/ambassador/box.png"
           alt="">
      <img src="https://qdzstatic01.hsz88.com/upload/direct_selling_qr_wx/b9ce9598-5767-45ac-8c0a-16101efd1fb7_applets_qr.jpg"
           alt=""
           class="qrcode">
    </div>
  </div>
</template>
<style scoped lang="less">
.main {
  height: 3572px;
  background-image: url("~@/assets/images/ambassador/bg1.png"),
    url("~@/assets/images/ambassador/bg2.png");
  background-repeat: no-repeat, no-repeat;
  background-position: left top, left bottom;
  background-position-x: center;
  position: relative;
  padding-top: 670px;

  .t1 {
    width: 74.7%;
    // height: 510px;
    margin-left: 13%;
  }
  .t2 {
    width: 71%;
    margin-left: 17%;
  }
  .t3 {
    width: 76.7%;
    margin-left: 17%;
  }
  .qecode-box {
    width: 24.2%;
    margin: 0 auto;
    position: relative;
  }
  .qrcode {
    width: 52.1%;
    height: 46.2%;
    display: block;
    position: absolute;
    top: 20%;
    left: 23%;
  }
}
</style>